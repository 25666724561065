<template>
  <div class="container p-0 ">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h2 class="text-white textTitle">Documentos</h2>
      <div v-if="profile">
        <button
          class="buttonDefault"
          @click="openModal('internalUpload')"
          v-if="cp === 'files'"
        >
          <img src="@/assets/image/uploads/up.svg" alt="up" />
          Upload
        </button>
        <div v-if="activeID && cp != 'files'" class="d-flex align-content-between align-items-center">
          <button class="mr-3 editSubFolder" @click="openModal('modalFolderSub', 'editSub', paramsDeleteAndEdit)">
            <img src="@/assets/image/iconesUpload/edit.svg" alt="edit"/>
          </button>
          <button
            class="buttonDefault"
            @click="!load ? openModal('modalFolderSub', 'createFolder') : ''"
          >
            <loader color="#f38235" size="20" v-if="load" />
            <span v-else>Nova Pasta</span>
          </button>
        </div>
      </div>
    </div>
    <div class="d-flex container p-0" id="subcCatCont">
      <div class="subContainer pl-0 col-3">
        <div class="p-3">
          <div
            class="d-flex justify-content-between align-items-center borderBtomSub pb-2"
          >
            <h3 class="subTitle">Subcategoria</h3>
            <div>
              <button
                class="buttonCircle deleteSub "
                @click="openModalDelete('modalFolderDeleteSub')"
                v-if="profile && showDeleteButton"
              >
                -
              </button>
              <button
                class="buttonCircle ml-2"
                @click="openModal('modalFolderSub', 'createSubFolder')"
                v-if="profile"
              >
                +
              </button>
            </div>
          </div>
          <h1 class="subNot" v-if="subCategoryData.length < 1">
            Nenhuma Subcategoria encontrada
          </h1>
          <subCategory
            v-for="items in subCategoryData"
            :key="items.id"
            :item="items"
            @subFolder="subFolder"
            :active="activeID"
          />
        </div>
      </div>

      <div class="col-9 folderCont">
        <div class="d-flex align-items-center borderBtom mt-4">
          <h3
            class="linkRouter mb-1 mr-2"
            @click="subRouter(routerFolderteste, false)"
          >
            <img src="@/assets/image/uploads/router.svg" alt="router" />
            {{ routerFolderteste }}
          </h3>

          <h3
            class="linkRouter mb-1 mr-2"
            v-if="routerSubFolder"
            @click="subRouter(routerSubFolder, true)"
          >
            <img src="@/assets/image/uploads/router.svg" alt="router" />
            {{ routerSubFolder.name }}
          </h3>

          <h3 class="linkRouter mb-1" v-if="routerFolderFile">
            <img src="@/assets/image/uploads/router.svg" alt="router" />
            {{ routerFolderFile }}
          </h3>
        </div>
        <div class="containerHeigthScrool">
          <h1 class="not" v-if="subFolderData.length < 1">
            Nenhuma pasta encontrada ou selecionada
          </h1>
          <transition name="vertical">
            <component
              :is="cp"
              :subFolder="subFolderData"
              @editFolder="editFolder"
              @editFile="editFile"
              @openFiles="openFiles"
              :Files="dataFiles"
              @download="downloadFile"
              @linkVideo="linkVideo"
              @deleteFile="deleteFile"
            />
          </transition>
          <h1 class="not" v-if="dataFiles.length < 1 && cp === 'files'">
            Nenhuma arquivo encontrado
          </h1>
        </div>
      </div>
    </div>

    <subModal
      :propsModal="objectModal"
      :dataField="dataField"
      :load="load"
      :disabled.sync="disabled"
      @submitform="submitform"
      @closeModal="closeModal"
    />

    <viewsModal :item="linkModal" :image64="image64" />

    <modalUpload
      :id="`internalUpload`"
      :itemData="containerSubFolder"
      @closeModal="closeModal"
    />
    <modalEditUpload
      :editFileItem="editFileItem"
      :load="load"
      @closeModal="closeModal"
    />

    <ModalDelete :nameModalDelete="'modalFolderDeleteSub'" :item="paramsDeleteAndEdit" @deleteFolder="deleteSubFolder" />
    <modalDegustacao/>
  </div>
</template>
<script>
import HTTP from "@/api_system";
import { mapGetters } from "vuex";
export default {
  components: {
    subCategory: () => import("@/components/arquivos/subCategory"),
    linkFolder: () =>
      import("@/components/arquivos/subCategory/linkFolder.vue"),
    files: () => import("@/components/arquivos/files"),
    modalUpload: () => import("@/components/arquivos/files/modalUpload"),
    subModal: () => import("@/components/arquivos/modalFolder/modalSub.vue"),
    viewsModal: () => import("@/components/arquivos/files/viewsModal.vue"),
    modalEditUpload: () =>
      import("@/components/arquivos/files/modalUpload/editUpload.vue"),
    ModalDelete: () =>
      import("@/components/arquivos/modalFolder/modalDelete.vue"),
    loader: () => import("@/components/utils/_Load"),
    modalDegustacao: () => import("@/components/modal/modalDegustacao"),
  },
  data() {
    return {
      cp: "linkFolder",
      subCategoryData: [],
      subFolderData: [],
      dataFiles: [],
      activeID: null,
      objectModal: {},
      dataField: {
        name: "",
        profiles: []
      },
      profilesCat: [],
      profilesSub: [],
      idCategory: null,
      containerSubFolder: [],
      linkModal: [],
      editFileItem: [],
      image64: "",
      load: false,
      disabled: false,
      secondCategoryId: [],
      showDeleteButton: false,
      paramsDeleteAndEdit: []
    };
  },
  computed: {
    profile() {
      return this.$store.getters.getUser.profile === 1;
    },
    ...mapGetters({
      routerFolderteste: "getRouterFolder",
      routerSubFolder: "getRouterSubFolder",
      routerFolderFile: "getRouterFolderFile"
    })
  },
  mounted() {
    this.getCategory(this.$route.params.id, false);
  },
  methods: {
    getReturnCreateFile() {
      $("#modalUpload").modal("hide");
      $(`#modalEditUpload`).modal("hide");
      this.getfiles(this.containerSubFolder.id);
    },

    subFolder(item) {
      this.showDeleteButton = true
      this.paramsDeleteAndEdit = item

      this.getCategory(String(item.id), true);
      this.activeID = item.id;
      this.idCategory = item.id;

      this.cp = "linkFolder";
      this.$store.commit("setRouterSubFolder", item);
      this.$store.commit("setRouterFolderFile", "");
    },

    subRouter(item, folder) {
      if (folder) {
        this.subFolder(item);
        return;
      }
      this.$router.go(-1);
      this.$store.commit("setRouterSubFolder", "");
    },

    getCategory(id, sub) {
      this.load = true;
      HTTP.get(`services/app/TrainingCategory/GetAll?categoryId=${id}`, {
        headers: { Authorization: `Bearer ${this.$store.getters.getterToken}` }
      })
        .then(res => {
          const { data } = res;
          if (sub) {
            this.subFolderData = data.result.trainingCategories; // sub
            this.profilesSub = data.result.trainingCategoryPermissions.map(
              x => x.profile
            );
          } else {
            this.subCategoryData = data.result.trainingCategories; // cat
            this.profilesCat = data.result.trainingCategoryPermissions.map(
              x => x.profile
            );
          }
          this.load = false;
        })
        .catch(err => {
          console.log(err);
          this.load = false;
        });
    },

    async submitform(id, category, editFolder) {
      const name = this.dataField.name;
      const profiles = this.dataField.profiles
        .filter(t => t.show)
        ?.map(t => t.id);

      if (!name) {
        this.toast("Nome da Pasta é obrigatório!", this.$toast.error);
        return;
      }

      if (!profiles.length) {
        this.toast("Selecione qual usuario tem acesso.", this.$toast.error);
        return;
      }

      this.disabled = true;
      const data = {
        id: editFolder,
        name: name,
        profiles: profiles,
        trainingCategoryId: id
      };
      console.log(this.disabled);

      try {
        await HTTP.post(`services/app/TrainingCategory/CreateOrEdit`, data, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`
          }
        });

        this.getCategory(editFolder ? this.idCategory : id, category);
        $("#modalFolderSub").modal("hide");
        this.dataField.name = "";
        this.dataField.profiles = [];

        if (editFolder) {
          this.getCategory(this.$route.params.id, false);
          this.toast("Pasta editada com Sucesso!", this.$toast);
        } else {
          this.toast("Pasta criada com Sucesso!", this.$toast.success);
        }
        this.disabled = false;
      } catch (e) {
        this.toast(e.response.data.error.message, this.$toast.error);
        this.disabled = false;
      }
    },

    openModalDelete (cp) {
      $(`#${cp}`).modal("show");
    },

    openModal(cp, params, item) {
      if (params === "createFolder") {
        this.objectModal = {
          title: "Criar nova Pasta",
          button: "Criar",
          id: String(this.idCategory),
          category: true,
          editFolder: null,
          cp: cp
        };
        this.getSubProfiles();
      } else if (params === "createSubFolder") {
        this.objectModal = {
          title: "Criar Nova SubCategoria",
          button: "Criar",
          id: this.$route.params.id,
          category: false,
          editFolder: null,
          item: this.subCategoryData,
          cp: cp
        };
        this.getCatProfiles();
      } else if (params === "editFolder") {
        this.objectModal = {
          title: "Editar Pasta",
          button: "Salvar",
          id: String(this.idCategory),
          category: true,
          editFolder: item.id,
          cp: cp
        };
        this.dataField.name = item.name;
        this.dataField.profiles = this.idProfileChecket(item)
      } else if (params === 'editSub') {
        this.objectModal = {
          title: `Editar SubCategoria ${item.name}`,
          button: "Salvar",
          id: this.$route.params.id,
          category: true,
          editFolder: item.id,
          cp: cp
        };
        this.dataField.name = item.name;
        this.dataField.profiles = this.idProfileChecket(item)
      }

      $(`#${cp}`).modal("show");
    },

    idProfileChecket ({trainingCategoryPermissions}) {
     const profileCheck = trainingCategoryPermissions.length > this.profilesSub.length ? trainingCategoryPermissions.filter(s => {
        this.profilesSub.some(t => t == s.profile);
      }).map(res => ({ id: res.profile, show: true })) : this.profilesSub.map(s => ({
        id: s,
        show: trainingCategoryPermissions.some(t => t.profile == s)
      }));

      return profileCheck
    },

    editFolder(item) {
      this.openModal("modalFolderSub", "editFolder", item);
    },

    getfiles(id) {
      HTTP.get(`services/app/TrainingUpload/GetAll?categoryId=${id}`, {
        headers: { Authorization: `Bearer ${this.$store.getters.getterToken}` }
      })
        .then(res => {
          const { data } = res;
          this.dataFiles = data.result;
        })
        .catch(err => {
          console.log(err);
        });
    },

    openFiles(item) {
      this.secondCategoryId = item.id;
      this.cp = "files";
      this.containerSubFolder = item;
      this.getfiles(item.id);
      this.$store.commit("setRouterFolderFile", item.name);
    },

    async downloadFile(item, params) {
      if (this.$store.getters.get_tenant.type == 4 && this.$store.getters.getUser.profile != 1) {
        $("#modalDegustacao").modal("show");
      } else {
        if (params) {
          $("#modalViews").modal("show");
          this.image64 = "";
          this.linkModal = [];
        } else {
          this.toast("Download iniciado", this.$toast);
        }

        try {
          const res = await HTTP.get(
            `services/app/TrainingUpload/GetFile?hashName=${item.hashName}`,
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.getterToken}`
              }
            }
          );
          const data = await res.data.result;

          const type = data.mimeType.split("/")[0];
          if (params) {
            $("#modalViews").modal("show");

            if (type !== "image") {
              this.image64 = "null";
            } else {
              this.image64 = `data:${data.mimeType};base64,${data.image64}`;
            }
            this.linkModal = item;
            return;
          }

          let a = document.createElement("a"); //Create <a>
          a.href = `data:${data.mimeType};base64,${data.image64}`; //Image Base64 Goes here
          a.download = data.nameFile; //File name Here
          a.click(); //Downloaded file
        } catch (error) {
          this.toast(error.response.data.error.message, this.$toast.error);
        }
      }
    },

    linkVideo(item) {
      $("#modalViews").modal("show");
      this.linkModal = item;
      this.image64 = "";
    },

    editFile(item) {
      $(`#modalEditUpload`).modal("show");
      this.editFileItem = item;
      this.$store.commit("editeFile", item);
    },

    getSubProfiles() {
      this.dataField.profiles = [];
      this.dataField.name = "";

      this.dataField.profiles = this.profilesSub.map(t => ({
        id: t,
        show: true
      }));
    },

    getCatProfiles() {
      this.dataField.profiles = [];
      this.dataField.name = "";
      this.dataField.profiles = this.profilesCat.map(t => ({
        id: t,
        show: true
      }));
    },

    deleteFile(item) {
      HTTP.delete(`services/app/TrainingUpload/Delete?uploadId=${item.id}`, {
        headers: { Authorization: `Bearer ${this.$store.getters.getterToken}` }
      })
        .then(res => {
          this.getReturnCreateFile();
          this.toast("Arquivo deletado com sucesso", this.$toast);
        })
        .catch(err => {
          console.log(err);
          this.toast(error.response.data.error.message, this.$toast.error);
        });
    },

    closeModal(cp) {
      $(`#${cp}`).modal("hide");
      this.getfiles(this.containerSubFolder.id);
    },

    deleteSubFolder (id) {
      HTTP.delete(`services/app/TrainingCategory/Delete?categoryId=${id}`, {
        headers: { Authorization: `Bearer ${this.$store.getters.getterToken}` }
      })
        .then(res => {
          $("#modalFolderDeleteSub").modal("hide");
          this.subFolderData = []
          this.dataFiles = []
          this.cp = 'linkFolder'
          this.activeID = null
          this.$store.commit("setRouterSubFolder", '');
          this.getCategory(this.$route.params.id, false);
          this.showDeleteButton = false
          this.toast("Pasta deletada com Sucesso!", this.$toast.error);
          return;
        })
        .catch(err => {
          this.toast(error, this.$toast.error);
          console.log(err);
        });
    }
  }
};
</script>
<style scoped>
* {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
}

h3 {
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

h3:hover {
  color: #dfdfeea6;
}

.textTitle {
  font-size: 24px;
  line-height: 36px;
}

.buttonDefault {
  width: 131px;
  height: 40px;
  background: #f38235;
  color: #fff;
  border-radius: 6px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
  transition: 0.2s ease-in-out;
}

.buttonDefault:hover {
  background-color: #e0a638;
}

.subTitle {
  font-size: 18px;
  color: #f38235;
  font-weight: 400;
}

.subContainer {
  background: #fff;
  width: 240px;
  height: 557px;
  border-radius: 12px;
  overflow: auto;
}

.buttonCircle {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  color: #fff;
  background: #f38235;
  border: none;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  outline: none;
}

.borderBtomSub {
  border-bottom: 1px solid #e9e9eb;
}
.borderBtom {
  border-bottom: 1px solid #e9e9eb29;
}

.linkRouter {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
}

.containerHeigthScrool {
  max-height: 500px;
  overflow: auto;
  padding: 0 10px 0 0;
}

.not {
  font-size: 21px;
  text-align: center;
  margin: 40px;
  color: #ffffff8c;
}

.subNot {
  font-size: 15px;
  text-align: center;
  margin: 20px;
  color: #f38135dc;
}

.deleteSub {
  background-color: #F65757;
}

.editSubFolder {
  outline: none;
  border: none;
  background: #2474b2;
  border-radius: 7px;
  transition: .2s ease-in-out;
  padding: 10px;
}

.editSubFolder:hover {
  background: #3586c4;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: #acdbff;
}

::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
@media screen and (max-width:767px){
  #subcCatCont{
    flex-direction: column;
  }
  .subContainer{
    width: 100%;
    max-width: 100%;
  }
  .folderCont{
    max-width: 100%!important;
  }
}
</style>
